import React, { useState } from "react"

import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"

import Carousel from "react-bootstrap/Carousel"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import SEO from "../../components/seo"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Paginator from "../../components/paginator"
import ReactCompareImage from "react-compare-image"
import ImageWithOverlay from "../../components/imageWithOverlay"

import leftImage1 from "../../images/B3/work/water-place-winter.jpg"
import rightImage1 from "../../images/B3/work/water-place-summer.jpg"
import leftImage2 from "../../images/B3/work/landscape-stair-summer.jpg"
import rightImage2 from "../../images/B3/work/landscape-stair-concert.jpg"
import leftImage3 from "../../images/B3/work/museum-normal.jpg"
import rightImage3 from "../../images/B3/work/museum-exhibition.jpg"

import fileOverlay from "../../images/B3/work/06_Masterplan_1920_over.png"

const SuedparkUrbanPlanning = ({ location }) => {
  const [timelineHeight, setTimelineHeight] = useState()

  return (
    <Layout>
      <SEO title="Luk Vermeulen" />
      <Container>
        <Carousel className="mt-3">
          <Carousel.Item>
            <Image
              filename="B3/work/carousel_00.jpg"
              alt="An overview rendering of a newly created park"
            />
            <Carousel.Caption className="d-none d-md-block">
              <p>
                An overview of the park with the planned residential development
                on the left and the expanding university on the right.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="B3/work/02_Bodenhof_1920x1080.jpg"
              alt="visualization of a new local restaurant"
            />
            <Carousel.Caption className="d-none d-md-block">
              <p>
                The local restaurant to activate the area and provide
                facilities.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="B3/work/03_Museum_1920x1080.jpg"
              alt="visualization of a planned museum in the new park"
            />
            <Carousel.Caption className="d-none d-md-block">
              <p>
                A museum as one of the actors to activate the park and draw
                visitors.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="B3/work/04_Platz_1920x1080.jpg"
              alt="visualization of a landscape stair"
            />
            <Carousel.Caption className="d-none d-md-block">
              <p>A landscape staircase with multiple uses.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <div className="row mt-3">
          <div className="col-md-4">
            <dl>
              <dt>Title</dt>
              <dd>
                B3 | Aachen Südpark | <small>Team of 5</small>
              </dd>
              <dt>Type</dt>
              <dd>Urban planning</dd>
              <dt>Year</dt>
              <dd>2019</dd>
            </dl>
          </div>
          <div className="col-md-8">
            <p>
              The "Südpark" is located in the south of Aachen. In the current
              situation most of its space is occupied by parking lots for the
              buildings of the FH-Aachen, allotment gardens and private gardens.
              Despite it's good connection and proximity to the central station,
              this almost rural area does not offer much to the multitude of
              different neighbours. Our proposal for a redevelopment suggests a
              programmatic and flexible green space, framed by different housing
              typologies and an expansion of the university campus. Serving many
              functions as the heart of the area, the park will host a museum
              and a restaurant, aswell as spaces that can be used flexibly from
              all people.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>Historic</h4>
          </div>
        </div>

        <Row className="">
          <Col xs="12" md="8" className="" id="historicColumn">
            <Image
              filename="B3/work/aachen-historical-development.jpg"
              alt="historical development map of aachen"
            />
          </Col>

          <Col md="4" className="d-none d-md-block">
            <ResponsiveEmbed aspectRatio="" className="h-100">
              <div
                className="timeline-overflow"
                style={{ height: timelineHeight }}
              >
                <ul className="timeline">
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>about 1750</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-01-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>about 1840</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-02-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>about 1860</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-03-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>about 1910</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-04-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>about 1960</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-05-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-info">
                      <span>Today</span>
                    </div>
                    <div className="timeline-marker"></div>
                    <div className="timeline-content">
                      <Image
                        filename="B3/work/historic-aachen-06-500.jpg"
                        alt="historical map aachen"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </ResponsiveEmbed>
          </Col>
        </Row>

        <div className="row">
          <div className="col-12">
            <h4>Site</h4>
          </div>
        </div>
        <div className="row d-none d-md-flex mt-3">
          <div className="col-md">
            <Image
              filename="B3/work/site-picture-01.jpg"
              alt="suedpark site picture"
            />
          </div>
          <div className="col-md">
            <Image
              filename="B3/work/site-picture-02.jpg"
              alt="suedpark site picture"
            />
          </div>
          <div className="col-md">
            <Image
              filename="B3/work/site-picture-03.jpg"
              alt="suedpark site picture"
            />
          </div>
          <div className="col-md">
            <Image
              filename="B3/work/site-picture-04.jpg"
              alt="suedpark site picture"
            />
          </div>
          <div className="col-md">
            <Image
              filename="B3/work/site-picture-05.jpg"
              alt="suedpark site picture"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md mb-3">
            <Image
              filename="B3/work/greenspaces-aachen_500.jpg"
              alt="map of greenspaces in aachen"
            />
          </div>
          <div className="col-md mb-3">
            <Image
              filename="B3/work/buildings-aachen_500.jpg"
              alt="map of building typologies in aachen"
            />
          </div>
          <div className="col-md mb-3">
            <Image
              filename="B3/work/building-uses-aachen_500.jpg"
              alt="analysis of building uses aachen"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h4>Proposal</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6 mb-3">
            <Image
              filename="B3/work/leitidee-isometrie.jpg"
              alt="isometric urban plan concept visualization"
            />
          </div>
          <div className="col-12 col-md-6 mb-3 d-flex flex-column justify-content-between">
            <div id="water_place" className="flex-grow-1">
              <ReactCompareImage
                leftImage={leftImage1}
                leftImageAlt="architectural concept visualization"
                rightImage={rightImage1}
                rightImageAlt="schematic architecture visualization"
              />
            </div>
            <div id="landscape_stair" className="flex-grow-1">
              <ReactCompareImage
                leftImage={leftImage2}
                leftImageAlt="architectural concept visualization"
                rightImage={rightImage2}
                rightImageAlt="schematic architecture visualization"
              />
            </div>
            <div id="museum_place" className="flex-grow-1">
              <ReactCompareImage
                leftImage={leftImage3}
                leftImageAlt="architectural concept visualization"
                rightImage={rightImage3}
                rightImageAlt="schematic architecture visualization"
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md mb-3">
            <Image
              filename="B3/work/10_Schnitt_2560.jpg"
              alt="urban plan section"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 mb-3">
            <ImageWithOverlay
              filenameBase="B3/work/06_Masterplan_1920.jpg"
              filenameTop={fileOverlay}
              alt="urban masterplan"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md mb-3">
            <Image
              filename="B3/work/08_500er_2000.jpg"
              alt="urban planning detail"
            />
          </div>
          <div className="col-md mb-3">
            <Image
              filename="B3/work/09_500er_2000.jpg"
              alt="urban planning detail"
            />
          </div>
        </div>

        <Carousel className="mt-3">
          <Carousel.Item>
            <Image
              filename="B3/work/11_Modellfoto_01_2000.jpg"
              alt="architectural model of urban plan close up"
              noOpacity={true}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="B3/work/11_Modellfoto_02_2000.jpg"
              alt="architectural model of urban plan close up"
              noOpacity={true}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="B3/work/11_Modellfoto_04_2000.jpg"
              alt="architectural model of urban plan full shot"
              noOpacity={true}
            />
          </Carousel.Item>
        </Carousel>

        <Paginator currentPathname={location.pathname} />
      </Container>
    </Layout>
  )
}

export default SuedparkUrbanPlanning
