import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const ImageWithOverlay = props => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const imageBase = data.images.edges.find(n => {
    return n.node.relativePath.includes(props.filenameBase)
  })
  // const imageTop = data.images.edges.find(n => {
  //   return n.node.relativePath.includes(props.filenameTop)
  // })

  if (!imageBase) {
    return <div>Image not found</div>
  }

  const imageFluidBase = imageBase.node.childImageSharp.fluid
  // const imageFluidTop = imageTop.node.childImageSharp.fluid

  return (
    <div className="stack-image-container">
      <Img
        style={{
          opacity: "1",
          transition: "0.5s ease",
          backfaceVisibility: "hidden",
        }}
        className={"stack-image-base"}
        alt={props.alt}
        fluid={imageFluidBase}
      />
      <img
        className="img-fluid stack-image-over px-3"
        src={props.filenameTop}
        alt="overlay"
      />
    </div>
  )
}

export default ImageWithOverlay
